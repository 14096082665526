@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* dropdown-transition.css */
.dropdown-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.dropdown-exit {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}



/* Add this CSS to your stylesheets */
.submenu-transition-enter {
  opacity: 0;
  height: 0;
}

.submenu-transition-enter-active {
  opacity: 1;
  height: auto;
  transition: opacity 1000ms, height 1000ms;
}

.submenu-transition-exit {
  opacity: 1;
  height: auto;
}

.submenu-transition-exit-active {
  opacity: 0;
  height: 0;
  transition: opacity 300ms, height 300ms;
}





/* styles.css */

/* Define the transition for the sidebar */
.sidebar-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.sidebar-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 700ms, transform 700ms;
}

.sidebar-exit {
  opacity: 1;
  transform: translateX(0%);
}

.sidebar-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 700ms, transform 700ms;
}



@media (min-width: 720px) {
  .container {
    max-width: 2000px;
  }
}