.css-13cymwt-control:hover {
    border-color: #9d9c9c !important;
  }
  
.css-13cymwt-control {
    border-color: #9d9c9c !important;
    border-radius: 3px !important;
    min-height: 39.535px !important ;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
.css-t3ipsp-control:hover {
    border-color: none !important;
  }
  
.css-t3ipsp-control {
    border-color: hsl(0, 100%, 51%) !important;
    box-shadow: 0 0 0 0px rgb(255, 0, 0) !important;
    border-radius: 3px !important;
    min-height: 39.535px !important ;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
  

.css-1jqq78o-placeholder{
    border-radius: 3px !important;
    font-size: 13px !important;
    background: rgb(255, 255, 255) !important;
    font-weight: 400;
    font-family: Poppins;
}