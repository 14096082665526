.add-form-page-header{
    align-items: center;
    border-radius: 1px;
    background: #FDECEC;
    padding: 15px;
}
.add-form-page-title h1{
    color: #EB5757;
    font-family: Poppins;
    font-size: 19px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
}


.add-form-close-button{
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    gap: 17px;
    border-radius: 4px;
    background: #FA6669;
}

.add-form-close-button a{
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
