.customer-history h2{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.history-table {
    overflow-y: auto;
    max-height: 160px;
  }

.history-table::-webkit-scrollbar {
    width: 8px;
  }
  
  .history-table::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 1px solid transparent;
    background-clip: content-box;
    height: 8px; 
  }
  
/* Firefox */
.history-table {
scrollbar-width: thin;
scrollbar-color: #888 transparent;
}
  
.history-table  table {
    width: 100%;
    border-collapse: collapse;
  }
  
.history-table  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
.history-table th {
    position: sticky;
    top: -1px;
    background-color: #f2f2f2;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    color: #323232;
  }

.history-table  td{
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}


.parent-category .react-tabs__tab {
    border: none !important;
    /* text-align: center; */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    width: 95%;
  }

.parent-category .react-tabs__tab:hover{
  background-color: #EB5757 !important;
  color: #fff !important;
  border-radius: 5px;
}

.child-category .react-tabs__tab  {
    border: none !important;
    /* text-align: center; */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    
  }
  
.react-tabs__tab-list {
    border: none !important;
    display: flex;
    justify-content: space-between;
    
  }

  

.react-tabs__tab-panel {
    font-size: 18px !important; /* Adjust the font size as needed */
  }


.react-tabs__tab--selected{
    background-color: #EB5757 !important;
    color: #fff !important;
    border-radius: 5px !important;
}

.parent-category .tb-list{
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 15px;
}

.child-category .tb-list{
    padding-top: 10px;
    padding-bottom: 10px;
}




.tabs-checkbox {
  appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #EB5757;
  background-color: #ffffff;
  margin-right: 3px;
}

.tabs-checkbox:checked {
  background-color: #ff0000;
  color: #ffffff;
}