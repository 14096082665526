.ticket-page-header{
  height: 90px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(20, 19, 19, 0.09);


   
}
.page-title h1{
  color: #E74A3B;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; 
  letter-spacing: 1.045px;
}

.technician-ticket-card{
  border-radius: 5px;
  border: 1px solid #EB5757;
  background: #FFF;
}

.technician-ticket-card .task{
  border-radius: 5px;
  background: #EB5757;
}
.technician-ticket-card .task-text{
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Source Code Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.technician-ticket-card .status{
  width: 75px;
  border-radius: 6px;
  border: 1px solid #EB5757;
  background: #F9FAFB;
  color: #323232;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.technician-ticket-card h1{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Source Code Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.technician-ticket-card .ttc-information p{
  color: #666;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Source Code Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

.ttc-job-start-btn{
  border-radius: 6px;
  background: #EB5757;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}