/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
  .tooltiptext {
    visibility: hidden;
    background-color: #EB5757;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    padding: 2px;
    font-size: 10px;
    font-weight: 600;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltiptext1{
    width: 50px;
  }
  .tooltiptext2{
    width: 95px;
  }

  /* Show the tooltip on hover */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }