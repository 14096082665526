.item-category .react-tabs__tab {
    border: none !important;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    width: 95%;
  }

.item-category .react-tabs__tab:hover{
  background-color: #ffffff !important;
  color: #EB5757 !important;
  border-radius: 5px;
}

  
.item-category .react-tabs__tab-list {
    border: none !important;
    display: flex;
    justify-content: space-between;
    
  }

  

.item-category .react-tabs__tab-panel {
    font-size: 18px !important; 
  }


.item-category .react-tabs__tab--selected{
    background-color: #fff !important;
    color: #EB5757 !important;
    border-radius: 5px !important;
    border: 1px solid #fff !important;
}

.item-category .tb-list{
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 15px;
}

