.page-header{
    min-height: 71px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(20, 19, 19, 0.09);


     
}

.page-title h1{
    color: #E74A3B;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 154%; 
    letter-spacing: 1.045px;
}

.page-search-input{
    height: 34px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    color: #74747B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    padding-left: 10px;
}

.page-search-button{
    width: 28px;
    height: 25px;
    border-radius: 3px;
    background: #EB5757;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.add-new-button{
    height: 34px;
    border-radius: 5px;
    border: 1px solid #F9333E;
    background: #F9333E;
}
.add-new-button a{
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.ant-table-thead .ant-table-cell {
    height: 67px !important;
    border-radius: 1px !important;
    background: #FDECEC !important;
}


.ant-table-column-sorter{
    color: #F9333E !important;
}


.ant-table-tbody .ant-table-cell{
    color: #292D32;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
}

.page-search-reset{
    height: 25px;
    border-radius: 3px;
    background: #EB5757;
    color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}